.carousalIntroCardContainer {
  height: 600px;
  width: 100%;
  background-color: #f6fafc;
}

.signupCardContainer {
  /* height: 600px; */
  width: 75%;
  background-color: #f6fafc;
}
.quote {
  /* background-color: #493AB1; */
  color: #493ab1;
  transform: rotate(180deg) scale(1.5);
}

.slick-dots li button {
  font-size: 12px;
}

.slick-prev:before,
.slick-next:before {
  display: none;
}

.nameOfPerson {
  font-size: 10px;
  padding-bottom: 20px;
  font-weight: bold;
  padding-left: 20px;
}

.verifiedIcon {
  color: #493ab1;
  text-align: left;
  margin-right: 5px;
}

.highlightedText {
  color: #493ab1;
  font-weight: 700;
}

.groupName {
  font-size: 10px;
  font-weight: 300;
}
.avatarStyle {
  color: orange !important;
  background: black !important;
  align-items: center;
  margin-left: 0 !important;
  border-radius: 5px !important;
}

.avatarMoreStyle {
  background: #dfe1e5 0% 0% no-repeat padding-box !important;
  border-radius: 5px !important;
  color: #2e384d !important;
  text-align: left;
  font-size: 12px !important;
  margin-left: 0 !important;
}
.text {
  text-align: left;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #2e384d;
  opacity: 1;
}
.trustText {
  text-align: left;
  text-decoration: underline;
  font-size: 20px;
  font-weight: bold;
  padding-top: 20px !important;
}
.slider {
  padding-bottom: 20px;
  padding-top: 20px;
}

.divider {
  margin-top: 20px;
}
.carousal {
  width: 80%;
}
.carousalContainer {
  width: 100%;
}
.carousalIntroSubContainer {
  text-align: start;
  margin-bottom: 20px;
}

.carousalImageContainer {
  text-align: center;
}

.carousalImageContainer img {
  height: 400px;
  display: initial !important;
}

.carousalCardContent {
  color: #ffffff;
  text-align: center;
  font-size: 28px;
}

.featureText {
  display: flex;
  font-size: 16px;
  padding-top: 20px;
}

.testimonialCard {
  border: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
}

@media only screen and (max-width: 780px) {
  .carousalIntroCardContainer,
  .carousalSecondIntroCardContainer,
  .carousalThirdIntroCardContainer,
  .signupCardContainer {
    width: 88% !important;
  }
  .carousalIntroCardContainer img,
  .carousalSecondIntroCardContainer img,
  .carousalThirdIntroCardContainer img {
    height: 60vh;
  }
  .carousalCardContent {
    color: #ffffff;
    text-align: center;
    font-size: 24px;
  }
  .testimonialCard {
    border: 1.5px solid #d9d9d9 !important;
    box-shadow: none !important;
    background-color: transparent !important;
    padding: 10px;
  }
  .trustText {
    text-decoration: none !important;
    font-size: 14px !important;
  }
  .featureText {
    font-size: 12px !important;
    font-weight: 300 !important;
  }
}
