.cardContainer {
  background: #ffffff;
  border: 1.5px solid #493ab1;
  padding: 26px;

  box-shadow: 0px 2px 0px rgba(14, 13, 12, 0.14);
  border-radius: 5px;
}

.creative_row {
  background: #ffffff;
  border: 1.5px solid #493ab1 !important;
  padding: 26px !important;
  box-shadow: 0px 2px 0px rgba(14, 13, 12, 0.14);
  border-radius: 5px;
  margin: 0 6px 16px 6px;
}

.OfferToastWrapper {
  position: absolute;
  top: 0px;
  width: 100%;
}

.shareIcon {
  cursor: pointer;
  margin-right: 30px;
}

.textPrimary {
  color: #493ab1 !important;
}

.sharingImage {
  max-width: 372px;
  max-height: 372px;
  width: 348px;
  object-fit: contain;
}

.mainContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 60px 80px;
  height: calc(100vh - 4px);
  background-color: #f6fafc;
  overflow-y: auto;
}

.hideBottomSpacing .MuiMenu-list {
  padding-bottom: 0;
}

.divfullwidthonboard {
  width: 100%;
  margin-bottom: 20px;
}

.emailnew {
  height: auto !important;
}

.cursorPointer {
  cursor: pointer;
}

.selectEmpty {
  border: none;
  width: 46px;
  background-color: #fff;
}

.selectEmpty * {
  overflow: unset !important;
}

.svg_container {
  justify-content: flex-start;
}

.usernameHelperText {
  text-decoration: "underline";
  font-weight: "bold";
  font-size: 17px;
}

.usernameHelpText {
  color: #4e55fa;
}

.selectEmpty span {
  display: none !important;
}

.selectEmpty:active,
.selectEmpty:focus {
  background-color: transparent !important;
}

.selectEmpty::after,
.selectEmpty::before {
  display: none !important;
}

.modal_close {
  position: absolute;
  right: -50px;
  top: -30px;
  font-size: 4vh;
  line-height: 0.6em;
  color: #555;
  cursor: pointer;
}

.modal_close:hover {
  color: #6c5dd3;
}

@media (min-width: 0px) and (max-width: 600px) {
  .simpleFormMargin > div {
    padding-bottom: 20px !important;
  }

  .sharingImage {
    width: 100%;
    height: 125px;
    margin-bottom: 10px;
    object-fit: cover;

    max-width: 100%;
    max-height: 100%;
  }
}

.emailnew input {
  height: 15px;
}

.accoundhandle {
  font-size: 14px;
  padding: 5px 0;
}

.newlogin {
  color: #493ab1;
  text-decoration: underline;
  cursor: pointer;
}

.newsignup {
  padding: 40px;
  padding-top: 0px;
}

.newverifyotp {
  margin-bottom: 20px !important;
}

.skip {
  color: #493ab1;
  text-decoration: underline;
  padding: 5px 0;
  text-align: center;
  font-size: 14px;
  width: 100% !important;
  cursor: pointer;
}

.mainnewsignupContainer {
  height: 100% !important;
}

.hintHelpText {
  font-style: italic !important;
  font-size: 15px;
}

.youtubenew input {
  color: #c62424;
}

.facebooknew input {
  color: blue;
}

.instanew input {
  color: #e1306c;
}

.linkedinnew input {
  color: #006699;
}

.webnew input {
  color: #493ab1;
}

.newonboardHeading {
  display: flex;
}

.newonboardHeading span {
  padding: 5px !important;
}

.italicHint {
  font-style: italic !important;
  font-size: 13px !important;
  color: rgba(0, 0, 0, 0.67) !important;
}

.newOnboardFix {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  height: 100vh;
  overflow: hidden;
}

a:hover {
  cursor: pointer;
}

.websiteDisplay {
  display: flex;
  justify-content: flex-start;
  justify-self: center;
  flex-direction: column;
  /* width: 70%; */
}

.progressBar {
  background-color: #f6fafc;
  height: calc(100vh - 4px); /* removing height of the linear progress */
}

.small_text {
  font-size: 20px;
  font-weight: 500;
}

.share_post_text {
  font-weight: 500;
}

.resendOtp {
  text-align: left;
  text-decoration: underline;
  font-size: 12px;
  color: #493ab1;
  cursor: pointer;
  padding-right: 20px;
}

.websiteHeader {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: left;
  margin: 10px 0;
  color: #2e384d;
}

.heightadjust {
  height: 245px;
}

.websiteOnboardDisplay {
  text-align: center;
}

.cursorPointer {
  cursor: pointer;
}

.websiteDomainLabel {
  color: #2e384d;
  padding-top: 1%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90%;
  margin-bottom: 0 !important;
}

.websiteDomainName {
  padding-left: 10px;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90%;
}

.websiteDomainSquare {
  display: flex;
}

.whatsappShareIcon {
  border-width: 1px;
  color: #55cd6c;
  padding-left: 5px !important;
  line-height: 1;
  transform: scale(1.5, 1.5);
  margin-left: 10px;
  margin-right: 30px;
  cursor: pointer;
}

.facebookShareIcon {
  border-width: 1px;
  border-color: blue !important;
  color: blue;
  padding-left: 5px !important;
  line-height: 1;
  transform: scale(1.5, 1.5);
  margin-right: 30px;
  cursor: pointer;
}

.timezone_dropdown {
  width: 100%;
  background-color: #f2f2f4;
}

.twitterShareIcon {
  color: #03a9f4;
  padding-left: 5px !important;
  border-width: 1px;
  line-height: 1;
  transform: scale(1.5, 1.5);
  margin-right: 30px;
  cursor: pointer;
}

.instagramShareIcon {
  border-width: 1px;
  border-color: red !important;
  color: red;
  padding-left: 5px !important;
  transform: scale(1.5, 1.5);
  cursor: pointer;
}

.websiteShareSubtitle {
  text-align: center;
  letter-spacing: 0px;
  color: #2e384d;
  opacity: 1;
  font-size: 24px;
}

.websiteSubheader {
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: left;
  margin: 10px 0;
  color: #2e384d;
}

.websiteLabel {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: start;
  color: #2e384d;
  padding-bottom: 10px;
}

.websiteInput {
  flex: 1;
  width: 100%;
  border: none !important;
  border-radius: 6px;
}

.websiteInput input {
  padding-left: 10px;
  width: 100%;
  border-color: transparent !important;
}

.websiteSubtitle {
  font-size: 12px;
  margin-top: 10px;
}

.websiteText {
  width: 90%;
}

.rectangle {
  padding: 20%;
  border: 2px dashed #2e384d;
  border-radius: 5px;
  opacity: 0.4;
  margin: 20px;
}

.square {
  flex: 1;
  padding: 15%;
  border: 2px dashed #2e384d;
  border-radius: 5px;
  opacity: 0.4;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.mainWebsiteContainer {
  background-color: #eeecf9;
  display: flex;
  justify-content: center;
  padding: 25px 0;
  height: 100vh;
  overflow-y: auto;
}

.mainWebsiteShareContainer {
  display: flex;
  justify-content: center;
  background-color: #f4f0ff;
  margin-top: 47px;
  padding-bottom: 30px;
}

.subWebsiteContainer {
  display: flex;
  padding: 10px;
  background: #ffffff;
  border: 1.5px solid #cbcdd2;
  box-shadow: 0px 2px 0px rgba(14, 13, 12, 0.14);
  border-radius: 12px;
  margin: 0 auto;
  max-width: 700px;
  flex-direction: column;
  position: relative;
  height: fit-content;
}

.subWebsiteShareContainer {
  display: flex;
  position: relative;
  justify-content: center;
  max-width: 1300px;
  width: 100%;
}

.websiteShareHeader {
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #2e384d;
}

.subContainer {
  display: flex;
  background-color: #f6fafc;
  flex: 1;
  position: relative;
  height: 100%;
}

.imageContainer {
  height: 600px;
  width: 50%;
}

.subWebsiteContainer {
  display: flex;
  position: relative;
}

.subWebsiteShareContainer {
  display: flex;
  position: relative;
  justify-content: center;
}

.dividerContainer {
  display: flex;
  align-items: center;
}

.dividerBorder {
  border-bottom: 1px solid #dfe1e5;
  margin-left: 20px;
  margin-right: 20px;
  width: 100%;
}

.imageContainer {
  height: 600px;
  width: 50%;
}

.container {
  display: flex;
  justify-content: flex-start;
  justify-self: center;
  align-items: center;
  flex-direction: column;
  width: 60%;
}

.dividerContent {
  padding: 0 10px 0 10px;
  color: #dfe1e5;
}

.tileWrapper {
  width: 100%;
}

.carousalDetail {
  width: 100%;
  background-color: inherit;
}

.containerSignup {
  display: flex;
  justify-content: flex-start;
  justify-self: center;
  flex-direction: column;
  margin-left: -30px;
  width: 60%;
}

.card {
  display: flex;
  align-self: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  border-color: #8e8e8e;
  overflow: inherit !important;
  padding: 0 !important;
}

.signUpCard {
  display: block !important;
  align-self: center;
  flex-direction: column;
  overflow: inherit !important;
  width: 100%;
  border-color: #8e8e8e;
}

.websiteOnboardCard {
  display: flex;
  align-self: center;
  flex-direction: column;
  overflow: inherit !important;
  width: 100%;

  border: 1.5px solid #cfcdc9;
  border-radius: 12px;
}

.websiteDomain {
  font-size: 14px;
  padding-left: 20px;
  background: rgb(223, 225, 230);
  border-radius: 20px;
  opacity: 0.68;
  flex: 1;
}

.signInText {
  font-size: 21px;
  margin: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #8e8e8e;
}

.exly {
  font-size: 35px;
  margin: 15px 0 14px 0;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: start;
  color: #493ab1;
  padding-left: 40px;
}

.exlyLogoMobile {
  font-size: 28px;
  font-weight: bold;
  color: #493ab1;
}

.exly2 {
  font-size: 35px;
  margin: 15px 0 14px 0;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: start;
  color: #493ab1;
}

.getStarted {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: start;
  color: #666666;
  margin-bottom: 20px;
  width: 100%;
}

.getStarted > span {
  padding-bottom: 5px;
}

.phoneLabel {
  font-size: 16px;
  font-weight: normal;
}

.getSentence {
  margin: 20px 40px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: start;
  color: #666666;
}

.feildLabel {
  margin-bottom: 5px;
  margin-top: 10px;
}

.signInButton {
  align-self: auto;
  height: 40px;
  outline: none !important;
  width: 100%;
  cursor: pointer;
  margin-bottom: 18px;
  /* margin: 0 40px 18px 40px; */
}

.signInButton button {
  background-color: white !important;
  border: 2px solid #000000 !important;
  border-radius: 5px !important;
  flex-direction: row;
  text-align: center;
  width: 100%;
  color: #000000 !important;
  /* padding: 0 12px !important; */
}

.signInButton div {
  background-color: white !important;
  padding-right: 27% !important;
  /* border-radius: 20px !important; */
}

.signInButton div svg g path {
  color: #ffff !important;
}

.orText {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: left;
  margin: 10px 0;
  color: #2e384d;
  margin-left: 40px;
}

.signInDiv {
  margin: 0 0 2vw 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: baseline;
  margin-left: 40px;
  margin-right: 40px;
}

.emailInput {
  width: 100%;
  height: 45px;
}

.websiteOutput {
  padding-right: 2% !important;
  width: 78%;
  height: 45px;
}

.continueEmail {
  display: inline-block;
  border-radius: 5px !important;
  cursor: pointer;
  color: #ffff !important;
  background: #493ab1 !important;
  align-self: flex-start;
  outline: none !important;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.31;
  text-align: center;
  margin-top: 0px !important;
}

.continueEmailDisabled {
  display: inline-block;
  border-radius: 20px !important;
  cursor: pointer;
  color: #6c5dd3 !important;
  border: 1px solid #6c5dd3 !important;
  align-self: center;
  outline: none !important;
  width: 40%;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: center;
}

.tnc {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  margin-top: 10px;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  align-self: flex-start;
}

.tnc a {
  color: #6c5dd3 !important;
  text-decoration: underline !important;
}

.inputName {
  display: flex;
  width: 100% !important;
}

.inputAboutName {
  display: flex;
  width: 100% !important;
  padding-bottom: 10px;
}

.basicDetailsHeaders {
  margin-top: 10px;
  display: flex;
  width: 100% !important;
  font-size: 16px;
  font-weight: normal;
}

.userName {
  text-transform: capitalize;
}

.buildWebsiteLabel {
  padding-top: 30px !important;
}

.copyListing {
  margin-bottom: -20px !important;
}

.shareListingIcons {
  padding-bottom: 20px !important;
  margin: 8px 0 !important;
}

.buildWebsiteButton {
  margin-bottom: 20px !important;
}

.leftInputName {
  width: 40%;
  margin-top: 16px;
}

.namefield {
  font-size: 16px;
  line-height: 25px;
}

.leftInputName h6 {
  font-size: 14px;
}

.rightInputName {
  width: 100%;
}

.simpleFormMargin {
  margin: 10px 25px 25px 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.basicDetails {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: #212121;
}

.whatsNextMargin {
  margin-right: 2%;
}

.basicDetailsInfoexample {
  font-size: 11px;
  color: #8e8e8e;
  display: block;
  margin-top: 7px;
  /* line-height: 0 !important; */
}

.aboutYouHeaders {
  text-align: left;
  font: normal normal normal 14px/16px;
  letter-spacing: 0px;
  color: #2e384d;
  margin-top: 5px;
  padding-bottom: 10px;
}

.websiteSpan {
  font-weight: 500;
}

.dividerWebsite {
  display: none;
}

.iconBackground {
  background-color: #f4f0ff;
  height: 100vh;
}

.closeIconStyles {
  float: right;
  cursor: pointer;
  margin-top: 10px;
}

.basicDetailsInfo {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: left;
  color: #7a869a;
}

.cardMediaStyle {
  height: 100%;
  width: 100%;
}

.basicDetailsMargin {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: #8e8e8e;
  margin: 2vw 0 3vw 0 !important;
}

.mobile_input {
  display: block !important;
}

.country_select {
  margin: 0 5px 0 0 !important;
  min-width: 110px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.country_select1 {
  width: 110px !important;
  min-width: 110px !important;
}

.country_select1 > div > div {
  padding-right: 24px !important;
}

.aboutbasicInfo {
  margin-top: -15px;
  width: 100% !important;
}

.organisationName {
  margin: 2vw 0 0 0 !important;
}

.savebuttonContainer {
  text-align: center;
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.save {
  height: 40px;
  width: auto;
  background-color: #df635d;
  outline: none !important;
  margin-bottom: 0px !important;
  border-radius: 20px;
  border: none !important;
  padding: 0 20px;
  color: #ffff;
}

.saveButton {
  height: 40px;
  width: auto;
  background-color: #df635d;
  outline: none !important;
  margin-bottom: 30px !important;
  border-radius: 20px;
  border: none !important;
  padding: 0 20px;
  color: #ffff;
}

.hostTerms {
  text-align: center;
  margin: 20px 0;
}

.checkAvailability {
  text-decoration: underline;
  color: #6c5dd3;
  cursor: pointer;
}

.basicTnc {
  margin: 0 0.5vw 0 0 !important;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: left;
}

.primarySocialMedia {
  margin: 1vw 0 !important;
}

.primarySocialMedia input {
  font-size: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.othersTextInput {
  margin-top: -5px !important;
  margin-bottom: -10px !important;
  width: 100%;
}

.errorOthers {
  margin-top: -5px !important;
  margin-bottom: -10px !important;
  border-bottom: 1px solid red;
  width: 100%;
}

.radioButtonContainer p {
  margin-left: 30px !important;
  margin-top: -10px;
}

.radioButtonContainer label {
  min-width: 38%;
}

.describeYouHead {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  margin: 30px 0 0 0;
  text-align: left;
  color: #212121;
}

.radioButtonContainer legend {
  color: #707070 !important;
}

.radiogroupabout {
  margin-top: 20px;
  width: 100% !important;
}

.required {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  display: block;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
  padding: 5px 0;
}

.requiredError {
  color: red;
  font-size: 12px;
}

.selectError {
  border: 1px solid red;
  border-bottom: 1px solid red !important;
  background-color: #f2f2f4;
  margin-top: 5px;
  border-radius: 5px;
  padding-left: 10px;
}

.checkboxGroupInput {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 30px 0 !important;
}

.skipButton {
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
  margin: 1vw 2vw -2vw 0;
  position: relative;
  color: #6c5dd3;
  text-decoration-color: #6c5dd3;
  text-decoration: underline;
  cursor: pointer;
  right: 0;
}

.profilePhoto {
  margin: 4vw 0 0 0;
}

.invalidError {
  color: red;
  display: flex;
  margin: 0 0 1vw 0vw;
  width: unset !important;
}
@media only screen and (min-width: 767px) {
  .invalidError {
    justify-content: flex-start;
  }
}
@media only screen and (max-width: 766px) {
  .invalidError {
    justify-content: center;
  }
}

.invalidNameError {
  color: red;
  display: flex;
  margin: 0vw 0 0vw 2vw;
  justify-content: flex-start;
}

.modalWelcome :focus {
  outline: none !important;
}

.welcomeHeader {
  display: flex;
  width: 100% !important;
  flex-direction: column;
  margin: 20px 0 0 0;
  align-items: center;
  justify-content: space-between;
}

.welcomeColumn {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  margin: 10px 10px !important;
}

.stepsAway {
  display: flex;
  width: 100% !important;
  justify-content: center !important;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  margin: 10px 0;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: center;
  color: #666666;
}

.bottomBar {
  display: flex;
  flex-direction: row;
  margin: 0 0 20px 0;
  justify-content: space-evenly;
  align-items: center;
}

.btnContinueEditing {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: right;
  color: #df635d;
  border: none;
  background: none;
}

.btnSample {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: right;
  color: white;
  background-color: #df635d;
  border-color: #df635d;
  padding: 0.5vw 1vw;
  border-radius: 20px;
}

.backButton {
  display: flex;
  align-self: flex-start;
  margin: 2vw;
  width: 10%;
  cursor: pointer;
}

.successLogo {
  display: flex;
  justify-content: center;
  align-self: center;
  width: 5%;
}

.enterOtpText {
  display: flex;
  margin: 2vw 0 1vw 0;
  align-self: center;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #666666;
}

.emailSentTo {
  display: flex;
  align-self: flex-start;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: start;
  color: #666666;
}

.otpText {
  display: flex;
  width: 100%;
  align-self: center;
}

.otpText input {
  align-items: center;
  text-align: start;
}

.verifyButton {
  width: 127px;
  background-color: #df635d !important;
  padding: 0.5vw 1vw !important;
  align-self: center;
  border-radius: 20px !important;
  color: white !important;
  font-weight: bold !important;
}

.ghostInput {
  border: none;
  width: 100%;
}

.inputTest {
  max-width: 60px;
  overflow: hidden;
  background-color: #fff !important;
}

.flex1 {
  flex: 1;
}

.sku_container {
  padding-top: 30px;
}

.sku_card {
  background-color: #f8f8f8;
  margin: 15px;
  padding: 15px;
  border: 1.5px solid #d9d9d9;
  min-height: 165px;
  position: relative;
}

.sku_card img {
  max-height: 66px !important;
  max-width: 80px;
}

.sku_img {
  width: 100px;
  height: auto;
}

.resendPassword {
  margin: 1vw 0 4vw 0;
  align-self: center;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: #df635d;
  cursor: pointer;
}

.errorText {
  color: #df635d;
  justify-self: center;
  font-size: 14px;
  font-weight: 600;
  display: block;
}

.productDemo {
  color: #6c5dd3;
  font-size: 16px;
}

.rightInputName p {
  word-break: break-word;
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.buttonSave {
  margin-top: 10px !important;
  background: #493ab1 !important;
  color: #ffffff !important;
  width: 100% !important;
}

.btnPrimary {
  background: #493ab1 !important;
  color: #ffffff !important;
  width: 100% !important;
  padding: 10px;
  border: none;
  margin-bottom: -14px;
  margin-top: -10px;
}

.hideBottomSpacing {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #493ab1;
  height: 8px;
}

.buttonSocialSave {
  margin-top: 10px !important;
  background: #493ab1 !important;
  color: #ffffff !important;
  width: 100% !important;
}

.websiteShareButtonSave {
  background: #493ab1 !important;
  color: #ffffff !important;
  width: 20% !important;
  font-size: 14px !important;
  white-space: nowrap;
}

.websiteShareCopyButtonSave {
  background: #493ab1 !important;
  color: #ffffff !important;
  margin-top: 30px !important;
  font-size: 12px !important;
  white-space: nowrap;
}

.websiteButtonSave {
  margin-top: 10px !important;
  background: #493ab1 !important;
  color: #ffffff !important;
  width: 90% !important;
}

.formControl {
  margin: 10px 0 !important;
  width: 100%;
  font-size: 16px;
  font-weight: normal;
}

.aboutYouText {
  width: 300px !important;
}

.select {
  background-color: #f2f2f4;
  border-bottom: none !important;
  margin-top: 5px;
  border-radius: 5px;
  color: black !important;
  max-width: 450px;
}

.select > div {
  padding: 10px;
  border-radius: 5px;
}

.social {
  background-color: #ffffff;
  border-bottom: none !important;
  margin-top: 5px;
  border: 1px solid #cbcdd2 !important;
  border-radius: 5px;
  color: black !important;
  width: 100% !important;
}

.social > div {
  padding-left: 10px;
  display: flex !important;
  padding-top: 10px;
  width: 100% !important;
}

.social::before {
  border-bottom: none !important;
  width: 100%;
}

.social:after {
  border-bottom: none !important;
  width: 100%;
}

.percentagevalue {
  display: none;
}

.linearhover:hover > .percentagevalue {
  display: block;
  color: black;
  font-size: 16px;
  padding: 5px 20px;
  position: absolute;
}

.select::before {
  border-bottom: none !important;
}

.select:after {
  border-bottom: none !important;
}

.selectError:after {
  border-bottom: none !important;
}

.selectError:before {
  border-bottom: none !important;
}

.ButtonProceed {
  color: white !important;
  padding: 6px 16px !important;
  font-size: 0.875rem !important;
  min-width: 64px;
  margin-bottom: 20px !important;
}

.formm {
  flex-direction: column !important;
  width: 100% !important;
}

.saveIcon {
  margin-right: 8px;
}

.placeholder {
  color: #707070;
  font-size: 14px;
}

.tickMark {
  float: right;
}

.selectDrop {
  position: relative !important;
  display: block !important;
}

.subdomainInput > div > input {
  text-transform: lowercase;
}

.describeoption {
  min-height: 0px !important;
  height: 30px;
}

@media only screen and (max-width: 780px) {
  .mainContainer {
    padding: 0;
    height: auto;
  }

  .svg_container {
    justify-content: space-between;
  }

  .shareIcon {
    margin-right: 0;
  }

  .cardContainerMob {
    text-align: center;
    padding: 8px 8px 0 8px !important;
  }

  .cardContainer {
    text-align: center;
    padding: 16px;
  }

  .container {
    display: flex;
    justify-content: center;
    justify-self: center;
    width: 100%;
    margin: 10vw 0;
    padding: 0 5px;
    position: relative;
  }

  .carousalDetail {
    display: flex;
    width: inherit;
    justify-self: center;
    justify-content: center;
  }

  .required {
    display: inline-block;
  }

  .buildWebsiteLabel {
    padding-top: 30px !important;
    text-align: center;
  }

  .buildWebsiteButton {
    margin-bottom: 20px !important;
    text-align: center !important;
  }

  .copyListing {
    margin-bottom: -20px !important;
    text-align: center !important;
  }

  .shareListingIcons {
    padding-bottom: 20px !important;
    margin: 8px 0 !important;
    text-align: center !important;
  }

  .subContainer {
    display: flex;
    width: 100%;
    margin: 0;
    flex-direction: column-reverse;
    justify-content: flex-end;
  }

  .mainWebsiteContainer {
    display: flex;
    justify-content: center;
    justify-self: center;
    width: 100%;
    flex-direction: row;
    position: relative;
    background-color: #ffffff;
    border: none;
    display: block;

    @media only screen and (max-width: 767px) {
      background-color: inherit;
    }
  }

  .subWebsiteContainer {
    background-color: #ffffff;
    display: flex;
    margin: 0 !important;
    width: 100%;
    flex-direction: column;
    border: none;
    box-shadow: none;
  }

  .aboutYouHeaders {
    text-align: left;
    font-size: 12px !important;
    font-weight: 500;
    letter-spacing: 0px;
    color: #2e384d;
    padding-top: 10px;
    padding-bottom: 5px;
  }

  .websiteDisplay {
    display: flex;
    justify-content: flex-start;
    justify-self: center;
    /* padding: 0 40px; */
    padding: 0 !important;
    width: 100%;
  }

  .dividerWebsite {
    display: flex;
    width: 90%;
    margin-left: 5% !important;
  }

  .carousalWebsiteDetail {
    width: 100%;
  }

  .website_title {
    font-weight: 700;
  }
  /* 
  .mainWebsiteShareContainer {
    height: 100%;
    width: 100%;
    background-color: white !important;
    display: block;
  } */

  .subWebsiteShareContainer {
    margin: 0;
    width: 100%;
  }

  .websiteShareSubtitle {
    font-size: 24px;
    font-weight: 500;
    text-align: center;
  }

  .websiteSubheader {
    text-align: center;
    padding-bottom: 20px;
  }

  .websiteSubtitle {
    text-align: left;
    padding-top: 20px;
    margin-left: 5%;
  }

  .websiteButtonSave {
    margin-bottom: 20px !important;
  }

  .websiteLabel {
    text-align: center;
  }

  .websiteShareButtonSave {
    font-size: 10px;
    margin-top: 20px !important;
    width: fit-content !important;
    margin-bottom: 20px !important;
    white-space: pre-wrap;
  }

  .websiteShareCopyButtonSave {
    font-size: 10px;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    white-space: pre-wrap;
    width: fit-content !important;
  }

  .containerSignup {
    display: flex;
    justify-content: flex-start;
    justify-self: center;
    flex-direction: column;
    padding: 35px 0px;
    background-color: #f6fafc;
    margin: 0;
    width: 100%;
  }

  .card {
    display: flex;
    flex-direction: column;
    width: 90%;
    border: 1.5px solid #d9d9d9 !important;
    padding: 0px;
    padding-top: 15px;
    box-shadow: none !important;
    max-height: 480px;
  }

  .RaCardContentInner-root-4:last-child {
    padding-bottom: 0px !important;
  }

  .signUpCard {
    display: flex !important;
    align-self: center;
    align-items: baseline;
    flex-direction: column;
    width: 90%;
    border: 1.5px solid #d9d9d9 !important;
    padding-top: 0px;
    box-shadow: none !important;
  }

  .websiteOnboardCard {
    display: flex;
    align-self: center;
    flex-direction: column;
    border-color: #8e8e8e;
    padding-top: 0px;
  }

  .signInText {
    font-size: 30px;
    margin: 10px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #8e8e8e;
  }

  .exly {
    font-size: 25px;
    margin: 50px 0 14px 0;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: normal;
    text-align: start;
    color: #493ab1;
    align-self: flex-start;
    padding-left: 20px;
  }

  .primarySocialMedia {
    margin: 40px 0px !important;
  }

  .othersTextInput {
    margin: 0 !important;
    width: 100%;
  }

  .containerSignup {
    display: flex;
    justify-content: flex-start;
    justify-self: center;
    flex-direction: column;
    padding: 35px 0px;
    background-color: #f6fafc;
    margin: 0;
    width: 100%;
  }

  .card {
    display: flex;
    flex-direction: column;
    width: 90%;
    border: 1.5px solid #d9d9d9 !important;
    padding: 0px;
    padding-top: 15px;
    box-shadow: none !important;
    max-height: 480px;
  }

  .RaCardContentInner-root-4:last-child {
    padding-bottom: 0px !important;
  }

  .signUpCard {
    display: flex !important;
    align-self: center;
    align-items: baseline;
    flex-direction: column;
    width: 90%;
    border: 1.5px solid #d9d9d9 !important;
    padding-top: 0px;
    box-shadow: none !important;
  }

  .websiteOnboardCard {
    display: flex;
    align-self: center;
    flex-direction: column;
    border-color: #8e8e8e;
    padding-top: 0px;
  }

  .signInText {
    font-size: 30px;
    margin: 10px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #8e8e8e;
  }

  .exly {
    font-size: 25px;
    margin: 50px 0 14px 0;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: normal;
    text-align: start;
    color: #493ab1;
    align-self: flex-start;
    padding-left: 20px;
  }

  .primarySocialMedia {
    margin: 40px 0px !important;
  }

  .othersTextInput {
    margin: 0 !important;
    width: 100%;
  }

  .errorOthers > div {
    margin: 0 !important;
    border-bottom: 1px solid red !important;
  }

  .getStarted {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: normal;
    color: #272522;
    margin-bottom: 10px !important;
    align-self: flex-start;
  }

  .dividerContainer {
    display: flex;
    align-items: center;
  }

  .dividerBorder {
    border-bottom: 1px solid #dfe1e5;
    width: 100%;
  }

  .dividerContent {
    padding: 0;
    color: #dfe1e5;
  }

  .getSentence {
    margin-bottom: 40px;
    margin-left: 0px !important;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 14px;
    word-break: break-word;
    line-height: 1.31;
    color: #666666;
    margin-bottom: 5px !important;
    width: 100%;
  }

  .signInButton {
    align-self: flex-start;
    height: 40px;
    outline: none !important;
    cursor: pointer;
    border-radius: 5px;
    margin: 0 !important;
    width: 100%;
  }

  .signInButton div {
    padding-right: 20% !important;
  }

  .orText {
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: normal;
    text-align: left;
    color: #212121;
    align-self: flex-start;
    margin-left: 20px;
    margin-top: 2rem;
  }

  .signInDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 14px 0;
    width: 100%;
    padding: 0px 20px 10px;
  }

  .emailInput {
    height: 40px;
    width: 100%;
  }

  /* custom select for mobiles */

  .textinput {
    height: 35px !important;
    width: 100%;
    margin-top: 5px;
    font-size: 12px;
  }

  .textinputError {
    height: 35px !important;
    width: 100%;
    margin-top: 5px;
    border: 1px solid red;
  }

  .optionModalBox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    background-color: #ffffff;
    border: none;
    border-radius: 5px;
    box-shadow: none;
    outline: none;
  }

  .optionModalInnerBox {
    padding: 10px 20px 0px 20px;
  }

  .flex_row {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .checklist_row {
    justify-content: flex-start;
    font-size: 15px;
    font-weight: 300;
    line-height: 18px;
    margin: 10px 0 18px 0;
    align-items: center;
  }

  .checkbox {
    margin-right: 10px;
  }

  input[type="checkbox"] {
    transform: scale(1.2);
  }

  .checkboxLabel {
    margin: 0 10px 0 10px;
    cursor: pointer !important;
  }

  /* custom select for mobiles */

  .continueEmail {
    display: inline-block;
    border-radius: 5px !important;
    cursor: pointer;
    align-self: center;
    outline: none !important;
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: normal;
    text-align: center;
    margin: 20px !important;
    text-transform: capitalize !important;
  }

  .continueEmailDisabled {
    display: inline-block;
    border-radius: 20px !important;
    cursor: pointer;
    color: #df635d !important;
    border: 1px solid#DF635D !important;
    align-self: center;
    outline: none !important;
    width: 240px;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: normal;
    text-align: center;
  }

  .tnc {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: start;
  }

  .tnc a {
    text-decoration: none !important;
  }

  .websiteSpan {
    text-align: center;
    padding-bottom: 10px;
    padding-top: 10px;
    display: inline-block;
    font-size: 14px;
  }

  .simpleFormMargin {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 100%;
  }

  .simpleFormMarginAboutyou {
    margin: 15px;
  }

  .mobileInput {
    display: block !important;
  }

  .leftInputName {
    width: 100%;
  }

  .rightInputName {
    width: 100% !important;
  }

  .basicDetailsInfoexample {
    display: none;
  }

  .hostTerms {
    text-align: left;
    padding: 0 16px;
    margin: -54px 0 0 0;
  }

  .basicDetails {
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: left;
    color: #212121;
  }

  .basicDetailsInfo {
    display: none;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 10px;
    margin: 1vw 0 3vw 0 !important;
    letter-spacing: normal;
    text-align: left;
    color: #8e8e8e;
  }

  .aboutbasicInfo {
    margin-top: -41px;
  }

  .radioButtonContainer label {
    min-width: 100%;
  }

  .radioButtonContainer fieldset {
    margin-top: 30px !important;
  }

  .organisationName {
    margin: 2vw 0 0 0 !important;
  }

  .basicTnc {
    margin: 0 0.5vw 0 0 !important;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: normal;
    text-align: left;
  }

  .profilePhoto {
    margin: 8vw 0 0 0;
  }

  .invalidNameError {
    color: red;
    display: flex;
    justify-content: center;
  }

  .modalWelcome :focus {
    outline: none !important;
  }

  .welcomeHeader {
    display: flex;
    width: 100% !important;
    flex-direction: column;
    margin: 10px 0 0 0;
    align-items: center;
    justify-content: space-between;
  }

  .welcomeColumn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 10px 10px !important;
  }

  .welcomeColumnImage {
    height: 100px;
  }

  .stepsAway {
    display: flex;
    width: 100% !important;
    justify-content: center !important;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    padding: 0px 20px;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: normal;
    text-align: center;
    color: #666666;
  }

  .bottomBar {
    display: flex;
    flex-direction: row;
    margin: 0 0 10px 0;
    justify-content: space-evenly;
    align-items: center;
  }

  .errorOthers > div {
    margin: 0 !important;
    border-bottom: 1px solid red !important;
  }

  /* .radioButtonContainer{
      width: 40%;
  } */
  .getStarted {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: normal;
    color: #272522;
    margin-bottom: 10px !important;
    /* margin-left:5px !important; */
    align-self: flex-start;
  }

  .dividerContainer {
    display: flex;
    align-items: center;
  }

  .dividerBorder {
    border-bottom: 1px solid #dfe1e5;
    width: 100%;
  }

  .dividerContent {
    padding: 0;
    color: #dfe1e5;
  }

  .getSentence {
    margin-bottom: 40px;
    margin-left: 0px !important;
    padding-left: 20px;
    font-size: 14px;
    word-break: break-word;
    line-height: 1.31;
    color: #666666;
    margin-bottom: 20px;
    width: 100%;
  }

  .signInButton {
    align-self: flex-start;
    height: 40px;
    outline: none !important;
    cursor: pointer;
    border-radius: 5px;
    margin: 0 !important;
    width: 100%;
  }

  .signInButton div {
    padding-right: 20% !important;
  }

  .orText {
    font-size: 18px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: normal;
    text-align: left;
    color: #212121;
    align-self: flex-start;
    margin-left: 20px;
    margin-top: 2rem;
  }

  .signInDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    margin: 14px 0;
    width: 100%;
    padding: 0px 20px 10px;
  }

  .emailInput {
    height: 40px;
    width: 100%;
  }

  /* custom select for mobiles */

  .imageContainer {
    display: none;
  }

  .mobileImgContainer img {
    width: 100% !important;
    height: 88vh;
  }

  .proceedContainer {
    height: 80vh !important;
  }

  .save {
    padding: 0 45px;
  }

  .otpContainer {
    height: 100vh;
    margin: 0;
    padding: 10px;
  }

  .buttonSave {
    margin: 20px;
    text-transform: capitalize !important;
    padding-top: 10px;
  }

  .buttonSocialSave {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    text-transform: capitalize !important;
  }

  .ButtonProceed {
    margin-bottom: 0px !important;
  }

  .subdomainInput > div {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .subdomainInput > div > input {
    margin-left: -5px !important;
  }

  .describeoption {
    min-height: 0px !important;
    height: 30px;
  }

  .heightadjust {
    height: auto;
  }

  .newsignup {
    padding: 20px;
    padding-top: 0px;
  }

  .newverify {
    margin: 0px !important;
  }

  .mainnewsignupContainer {
    padding: 0 !important;
    height: 100% !important;
  }

  .phoneLabel {
    font-size: 12px !important;
    font-weight: 500;
  }

  .basicDetailsHeaders {
    font-size: 14px !important;
    font-weight: 500;
  }

  .select {
    background-color: #ffffff;
    border: 1.5px solid #cbcdd2 !important;
    margin-top: 5px;
    height: 36px;
    border-radius: 5px;
    color: #8a8a8a !important;
    font-weight: 300;
  }

  .selectError {
    border: 1px solid red;
    border-bottom: 1px solid red !important;
    background-color: #ffffff;
    height: 36px;
    margin-top: 5px;
    border-radius: 5px;
    padding-left: 10px;
  }

  .social {
    background-color: #ffffff;
    border: 1.5px solid #cbcdd2 !important;
    margin-top: 5px;
    height: 36px;
    border-radius: 5px;
    color: #8a8a8a !important;
    font-weight: 300;
  }

  .textinput {
    height: 35px !important;
    width: 100%;
    margin-top: 5px;
    font-size: 12px;
  }

  .textinputError {
    height: 35px !important;
    width: 100%;
    margin-top: 5px;
    border: 1px solid red;
  }

  .optionModalBox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    background-color: #ffffff;
    border: none;
    border-radius: 5px;
    box-shadow: none;
    outline: none;
    /* padding: 10px 20px 5px 20px; */
  }

  .optionModalInnerBox {
    padding: 10px 20px 0px 20px;
  }

  .flex_row {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .checklist_row {
    justify-content: flex-start;
    font-size: 15px;
    font-weight: 300;
    line-height: 18px;
    margin: 10px 0 18px 0;
    align-items: center;
  }

  .checkbox {
    margin-right: 10px;
  }

  input[type="checkbox"] {
    transform: scale(1.2);
  }

  /* input[type=checkbox]::-ms-check {
      border: 0.15rem solid red;
  } */

  /*.checkbox:checked+label::before {
      background-color: #493AB1;
  }  */

  /* input[type=checkbox] {
      width: 14px;
      height: 14px;
      color: #ffffff;
      vertical-align: middle;
      -webkit-appearance: none;
      background: none;
      flex-grow: 0;
      border: 0.15rem solid #493AB1; */
  /* background-color: #FFFFFF; */
  /* transition: background 300ms;
      cursor: pointer;
  } */

  .checkboxLabel {
    margin: 0 10px 0 10px;
    cursor: pointer !important;
  }

  /* custom select for mobiles */

  .websiteInput input {
    width: 100%;
  }

  .continueEmail {
    display: inline-block;
    border-radius: 5px !important;
    cursor: pointer;
    /* color: #DF635D !important; */
    /* border:1px solid #DF635D !important; */
    align-self: center;
    outline: none !important;
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: normal;
    text-align: center;
    margin: 0 20px 20px 20px !important;
    text-transform: capitalize !important;
  }

  .continueEmailDisabled {
    display: inline-block;
    border-radius: 20px !important;
    cursor: pointer;
    color: #df635d !important;
    border: 1px solid#DF635D !important;
    align-self: center;
    outline: none !important;
    width: 240px;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: normal;
    text-align: center;
  }

  .tnc {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    /* margin: 10px 0 80px; */
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: start;
  }

  .tnc a {
    text-decoration: none !important;
  }

  .websiteSpan {
    text-align: center;
    padding-bottom: 10px;
    padding-top: 10px;
    display: inline-block;
    font-size: 14px;
  }

  .simpleFormMargin {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 100%;
    margin: 0px;
  }

  .btnContinueEditing {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: normal;
    text-align: right;
    color: #6c5dd3;
    border: none;
    background: none;
  }

  .btnSample {
    font-size: 14px;
    line-height: 1.31;
    letter-spacing: normal;
    text-align: center;
    color: white;
    background-color: #df635d;
    border-color: #df635d;
    padding: 5px 10px;
    border-radius: 20px;
  }

  .backButton {
    margin: 2vw;
    width: 20%;
    cursor: pointer;
  }

  .successLogo {
    display: flex;
    justify-content: center;
    align-self: center;
    width: 10%;
  }

  .enterOtpText {
    display: flex;
    margin: 5vw 1vw 1vw 1vw;
    align-self: center;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #666666;
  }

  .emailSentTo {
    display: flex;
    margin: 2vw 0 2vw 0;
    align-self: center;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #666666;
  }

  .otpText {
    display: flex;
    width: 100%;
    align-self: center;
    margin: 1vw 0 1vw 0 !important;
  }

  .verifyButton {
    background-color: #df635d !important;
    padding: 0.5vw 1vw !important;
    align-self: center;
    border-radius: 20px !important;
    color: white !important;
    font-weight: bold !important;
  }

  .cardMediaStyle {
    display: none !important;
  }

  .resendPassword {
    margin: 4vw 0 4vw 0;
    align-self: center;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: center;
    color: #df635d;
    cursor: pointer;
  }

  .errorText {
    color: #df635d;
    font-size: 12px !important;
    justify-self: center;
  }

  .imageContainer {
    display: none;
  }

  .mobileImgContainer img {
    width: 100% !important;
    height: 88vh;
  }

  .proceedContainer {
    height: 80vh !important;
  }

  .save {
    padding: 0 45px;
  }

  .otpContainer {
    height: 100vh;
    margin: 0;
    padding: 10px;
  }

  .buttonSave {
    margin: 20px;
    text-transform: capitalize !important;
    padding-top: 10px;
  }

  .buttonSocialSave {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    text-transform: capitalize !important;
  }

  .ButtonProceed {
    margin-bottom: 0px !important;
  }

  .inputColor {
    color: #493ab1;
  }

  .subdomainInput > div {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .subdomainInput > div > input {
    margin-left: -5px !important;
  }

  .describeoption {
    min-height: 0px !important;
    height: 30px;
  }

  .heightadjust {
    height: auto;
  }

  .newsignup {
    padding: 20px;
    padding-top: 0px;
  }

  .newverify {
    margin: 0px !important;
  }

  .mainnewsignupContainer {
    padding: 0 !important;
    height: 100% !important;
  }

  .phoneLabel {
    font-size: 12px !important;
    font-weight: 500;
  }

  .basicDetailsHeaders {
    font-size: 14px !important;
    font-weight: 500;
  }

  .formControl {
    font-size: 14px;
    font-weight: 500;
  }

  .select {
    background-color: #ffffff;
    border: 1.5px solid #cbcdd2 !important;
    margin-top: 5px;
    height: 36px;
    border-radius: 5px;
    color: #8a8a8a !important;
    font-weight: 300;
  }

  .selectError {
    border: 1px solid red;
    border-bottom: 1px solid red !important;
    background-color: #ffffff;
    height: 36px;
    margin-top: 5px;
    border-radius: 5px;
    padding-left: 10px;
  }

  .social {
    background-color: #ffffff;
    border: 1.5px solid #cbcdd2 !important;
    margin-top: 5px;
    height: 36px;
    border-radius: 5px;
    color: #8a8a8a !important;
    font-weight: 300;
  }

  .small_text {
    font-size: 14px;
    font-weight: 600;
  }

  .share_post_text {
    font-weight: 600;
  }
}

@media screen and (max-width: 320px) {
  .welcomeColumnImage {
    height: 80px;
  }

  .btnSample {
    font-size: 11px;
  }
}

.footerWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
}

.footerButtonWrapper {
  max-width: 400px;
  width: 100%;
  /* width: 400px; */
}

/* Large mobile media queries */

@media screen and (max-width: 1024px) {
  .creative_row {
    padding: 16px !important;
  }

  .footerWrapper {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    padding: 10px;
  }

  .small_text {
    white-space: pre-wrap;
  }

  .tileWrapper {
    background: #f4f0ff;
  }

  .websiteShareSubtitle {
    font-size: 18px;
  }

  .modal_close {
    right: 15px;
  }
}

@media only screen and (max-width: 1280px) {
  .modal_close {
    right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .modal_close {
    right: 15px;
    top: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .websiteShareHeader {
    font-size: 18px;
    display: flex;
    flex-direction: column;
  }
  .mainWebsiteShareContainer {
    margin-top: 5px;
  }
}
